<div class="row">
  <div class="col-md-12">
    <nb-card>
      <nb-card-body>
        <div class="flex-centered col-xl-4 col-lg-6 col-md-8 col-sm-12">
          <h2 class="title">404 - Ressource introuvable !</h2>
          <img src="../../assets/img/faba-logo.png">
          <small class="sub-title">La ressource à laquelle vous voulez acceder n'existe pas</small>
          <button nbButton fullWidth (click)="goToHome()" type="button" class="home-button">
            Retourner à l'accueil
          </button>
        </div>
      </nb-card-body>
    </nb-card>
  </div>
</div>
