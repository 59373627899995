import { Injectable, NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadingStrategy, Route } from '@angular/router'
import { AuthGuard } from './auth-guard.service';
import {
  NbAuthComponent,
  NbLoginComponent,
  NbRegisterComponent,
  NbLogoutComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';
import { NotFoundComponent } from './miscellaneous/not-found/not-found.component';
import { AuthentificationGuard } from './auth/auth-guard.service';
import { Observable, of } from 'rxjs';
import { JaneParentHomeModule } from './jane-parent-home/jane-parent-home.module';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

@Injectable()
export class PreloadAllModules implements PreloadingStrategy {
  preload(route: Route, load: () => Observable<any>): Observable<any> {
    
    if (route.data && route.data['preload']) {
      console.log('preload called');
      return load();
   } else {
      console.log('no preload');
      return of(null);
    }
  }
  
}

const routes: Routes = [
  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: () => import('./public-home/public-home.module')
      .then(m => m.PublicHomeModule),
  },

  {
    path: 'tracking',
    canActivate: [AuthentificationGuard],
    loadChildren: () => import('./jane-parent-home/jane-parent-home.module')
      .then(m => m.JaneParentHomeModule),
  },
  {
    path: 'staff',
    canActivate: [AuthentificationGuard],
    loadChildren: () => import('./jane-parent-home/jane-parent-home.module')
      .then(m => m.JaneParentHomeModule),
      data: { preload: true, delay:5000 }
  },

  
  {
    path: '',
    loadChildren: () => import('./public-home/public-home.module')
      .then(m => m.PublicHomeModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./jane-auth/jane-auth.module')
      .then(m => m.JaneAuthModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module')
      .then(m => m.AuthModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: [
      { provide: LocationStrategy, useClass: HashLocationStrategy },
  ]
})
export class AppRoutingModule { }
