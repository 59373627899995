import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
// import { any } from '../interfaces/user';
import { config } from 'src/config/local';

// export const config = {
//      apiUrl:"https://api.markpedia.com/"
//     //apiUrl:"https://talkerproject.herokuapp.com"
// }

@Injectable({
  providedIn: 'root'
})

// @Injectable()
export class AuthenticationService {
  public host: string = config.apiUrl;

  // baseUrl = this.host+"//"
   baseUrl = this.host
   private currentUserSubject = new BehaviorSubject<any>({} as any);
   public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

   loginStatus = new BehaviorSubject<boolean>(false);

  constructor(private httpClient: HttpClient, )
  { 
      
  }

  

  public login(url: string, user: any): Observable<any> {
    // const host=this.host+"//api/login"
    const host=this.host+"/api/login"
    return this.httpClient
      .post<any>(host+url, user, {responseType: 'json'})
      .pipe(map((response: any) => {
        console.log(response);
        this.loginStatus.next(true);
        console.log("SUCCES LOGIN");
        this.currentUserSubject.next(response);                  
        console.log(this.currentUserSubject);
        return response;
      }));
  }

  public postSignUp(url: string, user: any): Observable<any> {
    // const host=this.host+"//api"
    const host=this.host+"/api"
    return this.httpClient
      .post<any>(host+url, user)
      .pipe(map((response: any) => {
        console.log(response);

        // localStorage.setItem("markpediausersessionid",JSON.stringify(response))
        this.currentUserSubject.next(response);

                   
        console.log(this.currentUserSubject);

        return response;
      }));
  }

  
  
  createNewUser(url: string, user: any) {
    const myurl = `${this.baseUrl}${url}`;
    return new Promise<any>((resolve, reject) => {
      this.httpClient.post(
        myurl, user)
        .subscribe(
          (response: any) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  upDateUser(url: string, user: any) {
    const myurl = `${this.baseUrl}${url}`;
    return new Promise<void>((resolve, reject) => {
      this.httpClient.put(
        myurl, user)
        .subscribe(
          (response: any) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  getsEntite(url: string) {
    const myurl = `${this.baseUrl}${url}/`;
    return new Promise<any>((resolve, reject) => {
      this.httpClient.get(myurl).subscribe(
        (user: any) => {
            resolve(user);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
  getsObject(url: string) {
    const myurl = `${this.baseUrl}${url}`;
    return new Promise<void>((resolve, reject) => {
      this.httpClient.get(myurl).subscribe(
        (user: any) => {
            resolve(user);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
  getEntity(url: string, entity: any) {
    const myurl = `${this.baseUrl}${url}`;
    return new Promise<void>((resolve, reject) => {
      this.httpClient.post(
        myurl, entity)
        .subscribe(
          (response: any) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  confirmAccount(url: string) {
    return new Promise((resolve, reject) => {
      this.httpClient.get(url).subscribe(
        (response: any) => {
        resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  forgotPassword(url: string, email: string) {
    const myurl = `${this.baseUrl}${url}`;
    return new Promise<void>((resolve, reject) => {
      this.httpClient.post(
        myurl,
        {email : email})
        .subscribe(
          (response: any) => {
           resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getUserById(url: string, id: any) {
    const myurl = `${this.baseUrl}${url}${id}/`;
    return new Promise<void>((resolve, reject) => {
      this.httpClient.get(myurl
      ).subscribe(
        (user: any) => {
            resolve(user);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
  deleteEntityById(url: string, id: string) {
    const myurl = `${this.baseUrl}${url}${id}/`;
    return new Promise<void>((resolve, reject) => {
      this.httpClient.delete(myurl).subscribe(
        (user: any) => {
            resolve(user);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

 
  getEntityById(url: string) {
    const myurl = `${this.baseUrl}${url}/`;
    return new Promise<any>((resolve, reject) => {
      this.httpClient.get(myurl
      ).subscribe(
        (user: any) => {
            resolve(user);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
 

}
