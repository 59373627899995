import { Component, OnInit } from '@angular/core';
import {LocalStorageService, SessionStorageService,LocalStorage,SESSION_STORAGE,SessionStorage} from 'ngx-webstorage';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'markpedia';
  errorMessage = ''; 

 constructor(private localSt:LocalStorageService, private sessionSt: SessionStorageService){
  
 }
  ngOnInit(){
    
  }

}
