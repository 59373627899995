import { Configuration } from './model';
export const config: Configuration = {
   apiUrl:'https://api.markpedia.com/',
   apiUrlImage: 'https://api.markpedia.com',
   baseUrl: 'https://api.markpedia.com/',


  // apiUrl:'http://localhost:8000/',
  // apiUrlImage: 'http://localhost:8000',
  // baseUrl: 'http://localhost:8000/',

};
