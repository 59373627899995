import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { NbAuthService } from '@nebular/auth';

@Injectable()
export class AuthentificationGuard implements CanActivate  {

    constructor(private authService: NbAuthService, private router: Router) {
    }

    canActivate() {
        let authenticate = false;
        const token = localStorage.getItem('token');
        const user = localStorage.getItem('my_user');
        if (JSON.parse(user).user.is_manager) {
          authenticate = true;
        } else {
          this.router.navigateByUrl('/login');
        }
        return authenticate;
      }


}