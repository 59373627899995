import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Component, OnInit  } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbThemeModule, NbLayoutModule,NbSidebarModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularFireModule } from 'angularfire2';
import { FormsModule } from '@angular/forms';
import { NbPasswordAuthStrategy, NbAuthModule,NbAuthJWTToken,NbAuthService} from '@nebular/auth';
import { NbSecurityModule,NbRoleProvider } from '@nebular/security';
import {NgxWebstorageModule, LocalStorageService,LocalStorage, SessionStorageService,} from 'ngx-webstorage';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { AuthGuard } from './auth-guard.service';
import { ReactiveFormsModule } from '@angular/forms';  
import {config} from 'src/config/local'
import {
  NbChatModule,
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbToastrModule,
  NbWindowModule,

} from '@nebular/theme';
import { MiscellaneousModule } from './miscellaneous/miscellaneous.module';

// const config: SocketIoConfig = { url: 'http://localhost:3000', options: {} };
// export const firebaseConfig = {
//   apiKey: 'AIzaSyCQZbSXCXVocc6mxgKu9payOg8NbjDnRRQ',
//   authDomain: 'jane-faba.firebaseapp.com',
//   databaseURL: 'https://jane-faba.firebaseio.com',
//   storageBucket: 'jane-faba.appspot.com',
//   messagingSenderId: '520931720258'
// }; 
import { CKEditorModule } from 'ng2-ckeditor';
import { AuthenticationService } from './auth.service';
import { AuthService } from './authservice.service';
import { AuthInterceptorService } from './autservice.interceptor';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatCardModule } from '@angular/material/card';
import { MatCommonModule } from '@angular/material/core';
import { ApiService } from './api.service';
import { AuthentificationGuard } from './auth/auth-guard.service';
import { ChartsModule   } from 'ng2-charts';

// import { TagInputModule } from 'ngx-chips';
//export let lacal:LocalStorageService;
//export const tenant = new AppComponent(lacal).retrieveTenant();



@NgModule({
  declarations: [
    AppComponent,
    
  ],
  imports: [
    
    CKEditorModule,
    MatCommonModule,
    ReactiveFormsModule, 
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    NgxPaginationModule,
    MiscellaneousModule,
    BrowserAnimationsModule,
    NbThemeModule.forRoot({ name: 'default' }),
    NbLayoutModule,
    NbEvaIconsModule,
    HttpClientModule,
    FormsModule,
    // TagInputModule,
    // AngularFireModule.initializeApp(firebaseConfig),
    // SocketIoModule.forRoot(config),
    NgxWebstorageModule.forRoot(),
    NbSecurityModule.forRoot({
      accessControl: {
        is_tracking_agent: {
          view:['tracking','tracking/*'],
          create: [],
          remove:[]
        },
        is_staff: {
          view:['/','staff'],
          create: [],
          remove:[]
        },
      }
    }),
    NbAuthModule.forRoot({
      strategies: [
        NbPasswordAuthStrategy.setup({
          name: 'email',
        })
         
      ],
         forms: {},
       }),
    ChartsModule ,
    
  ],
  providers: [AuthGuard,AuthenticationService,
    AuthService , 
    AuthentificationGuard,
    ApiService,
    {
     provide: HTTP_INTERCEPTORS,
     useClass: AuthInterceptorService,
     multi: true
    }, ],
  bootstrap: [AppComponent],
  exports: [
    ChartsModule 
  ]
})
export class AppModule {

  @LocalStorage()
  public tenant;
  public home;
    constructor(NbAuthService: NbAuthService, authservice : AuthenticationService, authStrategy: NbPasswordAuthStrategy,private localSt:LocalStorageService,private sessionSt:SessionStorageService){
      //this.tenant = sessionStorage.getItem('tenant') != null ? sessionStorage.getItem('tenant')+'.' : "" ;
      this.home = "backoffice";
      authStrategy.setOptions({
        name: 'email', 
        token: {
          class: NbAuthJWTToken,
          key: 'token',
        },       
        baseEndpoint: config.apiUrl,
        login: {
          endpoint: 'api/login-admin/',
          method:'post',
          redirect: {
            
            failure: null,
        },
        },
        register: {
          endpoint: 'auth/agent/register',
          method:'post'
        },

        requestPass: {
          endpoint: 'auth/forgot-password',
          method:'post'
        },
        resetPass: {
          endpoint: 'auth/reset-password',
          method:'post'
        },
      });
      
    }
   

 }

 