import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthService } from "./authservice.service";

@Injectable({
   providedIn: 'root',
 })
export class AuthInterceptorService implements HttpInterceptor {
    constructor(private authService: AuthService,
      private router: Router) {} 
   intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {  
      const token = this.authService.getAuthToken();

      if (request.headers.get("/auth/login")){
         return next.handle(request);
      }else{
         if (token) {
            // If we have a token, we set it to the header
            request = request.clone({
               setHeaders: {Authorization: `Bearer ${token}`}
            });
         }
      }
   
    return next.handle(request).pipe(
        catchError((err) => {
          if (err instanceof HttpErrorResponse) {
              if (err.status === 401) {
               console.log("redirection sur le login")
               this.router.navigate(['/auth/login']);
           }
        }
        return throwError(err);
      })
     )
    }
  }
  