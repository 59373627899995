import { Injectable } from '@angular/core';

import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable ,  throwError } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import {config} from 'src/config/local';

// export const config = {
//     baseUrl:"http://localhost:8000/"
//     //baseUrl:"https://api.markpedia.com/"
//    //apiUrl:"https://talkerproject.herokuapp.com"
// }

@Injectable()
export class ApiService {

  public tenant = new BehaviorSubject<string>({} as '');
  public user$ = new BehaviorSubject<any>({} as any);
  private tenantApp = localStorage.getItem('mastergPharmaSessionTenant');
  private headers;
  constructor (
    private http: HttpClient,
  ) {
    console.log('config.baseUrl', config.baseUrl);

  }

  getBasicAuth() {
    // const authdata = window.btoa('+237698601499:zangue');
    const token = localStorage.getItem('MarkpediaAccessToken');
    const authdata = window.btoa('+33655757915:12345678');
    return `Bearer ${token}`;
  }


  post(path: string, data: any): Observable<any> {
    const url = `${config.baseUrl}${path}/`;
    console.log('URL', url);
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getBasicAuth(),
    });
    return this.http.post(url, data, { headers: reqHeader },
    ).pipe(catchError(this.formatErrors));
  }

  postLogin(path: string, data: any): Observable<any> {
    const url = `${config.baseUrl}${path}/`;
    console.log('URL', url);

    return this.http.post(url, data,
    ).pipe(catchError(this.formatErrors));
  }

  get(path: string): Observable<any> {
    const url = `${config.baseUrl}${path}/`;
    console.log('URL', url);

    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getBasicAuth(),
    });

    return this.http.get<any>(url, { headers: reqHeader })
      .pipe(
        catchError(this.formatErrors),
      );
  }


  getRencontre(path: string): Observable<any> {
    const url = `${config.baseUrl}${path}`;
    console.log('URL', url);

    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getBasicAuth(),
    });

    return this.http.get<any>(url, { headers: reqHeader })
      .pipe(
        catchError(this.formatErrors),
      );
  }

  put(path: string, data: any): Observable<any> {
    const url = `${config.baseUrl}${path}/`;
    console.log('URL', url);

    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getBasicAuth(),
    });
    return this.http.put(url, data, { headers: reqHeader })
    .pipe(catchError(this.formatErrors));
  }

  delete(path: string): Observable<{}> {
    const url = `${config.baseUrl}${path}/`;
    console.log('URL', url);

    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getBasicAuth(),
    });
    return this.http.delete<any>(url, { headers: reqHeader })
      .pipe(catchError(this.formatErrors));
  }


  public login(user: any, path: string) {
    const url = `${config.baseUrl}${path}/`;
    console.log('URL', url);
    return new Promise((resolve, reject) => {
      this.http.post(url, user).subscribe(
        (response: any) => {
          this.getUser(response.user, user.identifiant);
          this.getToken(user.username, user.password, user.identifiant);
          localStorage.setItem('mastergPharmaSessionId', JSON.stringify(response.user));
          this.tenant.next('response');
          // console.log('Nom du tenant en cours d\'utilisation', response.tenantName);
          // console.log('any id', response.user);
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });

  }

  public getUser(id: any, tenant: any) {
    const url = `http://${tenant}${config.baseUrl}/customuser/${id}/`;
    console.log('URL', url);
    return new Promise((resolve, reject) => {
      this.http.get(url).subscribe(
        (response: any) => {
          resolve(response);
          this.emitUser(response);
          console.log('any : ', response);
        },
        (error) => {
          reject(error);
        },
      );
    });
  }

  public getToken(username: string, password: string, tenant: any) {
    const url = `http://${tenant}${config.baseUrl}/api/token/`;
    console.log('URL', url);
    const user = {
      username: username,
      password: password,
    };
    return new Promise((resolve, reject) => {
      this.http.post(url, user).subscribe(
        (response: any) => {
          localStorage.setItem('mastergPharmaSessionTokenAuth', JSON.stringify(response.access));
          localStorage.setItem('mastergPharmaSessionTokenRefresh', JSON.stringify(response.refresh));
          console.log('any Access Token', response.access);
          console.log('any Refresh Token', response.refresh);
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });
  }

  emitUser(user: any) {
    this.user$.next(user);
  }



  private formatErrors(error: any) {
    return  throwError(error.error);
  }
}